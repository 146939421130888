<template>
  <b-sidebar
    id="upload-sidebar"
    :visible="isUploadSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="clearForm"
    @change="(val) => $emit('update:is-upload-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          编辑合同
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          class="p-2"
          @submit.prevent="upload()"
          @reset.prevent="clearForm"
        >

          <b-form-group
              label="合同"
              label-for="contract"
            >
            <b-form-file
              v-model="file"
              accept=".pdf"
              :placeholder="contractFilePlaceholder"
            />
            </b-form-group>


          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              :disabled="isSaving"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
            保存
            </b-button>
           
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              取消
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,BFormTextarea,BFormFile,BSpinner,BBadge
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { temp } from '@/@core/directives/animations'
import flatPickr from 'vue-flatpickr-component'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormFile,
    BFormTextarea,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    flatPickr,
    temp,
    BBadge
},
  directives: {
    Ripple,
  },
  model: {
    prop: 'isUploadSidebarActive',
    event: 'update:is-upload-sidebar-active',
  },
  props: {
    companyData: {
      type: Object,
      required: true,
    },
    editingContractData: {
      type: Object,
      required: true,
    },
    isUploadSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  
  data() {
    return {
      editingContractDataCopy:{
        "contract_name":""
      },
      isSaving: false,
      file: null,
      contractStartDate: null,
      contractDueDate: null,
    }
  },
  computed:{
    contractFilePlaceholder(){
      if(this.editingContractDataCopy.pending_contract_name){
        return this.editingContractDataCopy.pending_contract_name
      }
      if(this.editingContractDataCopy.contract_name){
        return this.editingContractDataCopy.contract_name
      }
      return '请选择或拖拽文件至此（仅支持pdf格式）...'
    },
    contractRemainDays(){
      if(this.contractStartDate && this.contractDueDate){
        return Math.floor((new Date(this.contractDueDate).getTime() - new Date(this.contractStartDate).getTime())/(24*3600*1000))
      }
      return 0
    }
  },
  methods:{
    upload(){
      this.isSaving = true
      const formData = new FormData()    
      if (this.file) formData.append('contract', this.file)
      formData.append('company_id',this.companyData.company_id)
      formData.append('contract_id',this.editingContractDataCopy.contract_id)
      this.$axios.post('/partner/companies/edit_contract',formData).then(res=>{
        this.isSaving = false
        if(res.data.status == "ok")
          this.clearForm()
          this.$emit('update:is-upload-sidebar-active', false)
          this.$emit('refresh-contracts')
      })
    },
    clearForm: function(){
      this.$refs.refFormObserver.reset()
      this.file=null
      const today  = new Date()
      const year = today.getFullYear()
      const month = today.getMonth() + 1
      const day = today.getDate()
      this.contractStartDate = year + '-' + month + '-' + day
      this.contractDueDate = year + 1 + '-' + month + '-' + day
    },
  },
  created(){
    const today  = new Date()
    const year = today.getFullYear()
    const month = today.getMonth() + 1
    const day = today.getDate()
    this.contractStartDate = year + '-' + month + '-' + day
    this.contractDueDate = year + 1 + '-' + month + '-' + day
  },
  watch: {
    isUploadSidebarActive(val) {
      if (val) {
        this.editingContractDataCopy = JSON.parse(JSON.stringify(this.editingContractData))
      }
    },
  },
  setup() {
    const {
      getValidationState,
    } = formValidation()

    return {
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
