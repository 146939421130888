<template>
  <b-sidebar
    id="upload-sidebar"
    :visible="isUploadSowSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="clearForm"
    @change="(val) => $emit('update:is-upload-sow-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          编辑项目任务书
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          class="p-2"
          @submit.prevent="upload()"
          @reset.prevent="clearForm"
        >

          <b-form-group
              label="项目任务书"
              label-for="sow"
            >
            <b-form-file
              v-model="file"
              accept=".pdf"
              :placeholder="sowFilePlaceholder"
            />
            </b-form-group>

          <div class="d-flex mt-2">
            <b-button
              :disabled="isSaving"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
            保存
            </b-button>
           
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              取消
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,BFormTextarea,BFormFile,BSpinner,BBadge
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { temp } from '@/@core/directives/animations'
import flatPickr from 'vue-flatpickr-component'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormFile,
    BFormTextarea,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    flatPickr,
    temp,
    BBadge
},
  directives: {
    Ripple,
  },
  model: {
    prop: 'isUploadSowSidebarActive',
    event: 'update:is-upload-sow-sidebar-active',
  },
  props: {
    companyData: {
      type: Object,
      required: true,
    },
    editingSowData: {
      type: Object,
      required: true,
    },
    isUploadSowSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  
  data() {
    return {
      editingSowDataCopy:{},
      isSaving: false,
      file: null,
    }
  },
  computed:{
    sowFilePlaceholder(){
      if(this.editingSowDataCopy.pending_sow_name){
        return this.editingSowDataCopy.pending_sow_name
      }
      if(this.editingSowDataCopy.sow_name){
        return this.editingSowDataCopy.sow_name
      }
      return '请选择或拖拽文件至此（仅支持pdf格式）...'
    },
  },
  methods:{
    upload(){
      this.isSaving = true
      const formData = new FormData()    
      if (this.file) formData.append('sow', this.file)
      formData.append('company_id',this.companyData.company_id)
      formData.append('sow_id',this.editingSowDataCopy.sow_id)
      this.$axios.post('/partner/companies/edit_sow',formData).then(res=>{
        this.isSaving = false
        if(res.data.status == "ok")
          this.clearForm()
          this.$emit('update:is-upload-sow-sidebar-active', false)
          this.$emit('refresh-sows')
      })
    },
    clearForm: function(){
      this.$refs.refFormObserver.reset()
      this.file=null
    },
  },
  watch: {
    isUploadSowSidebarActive(val) {
      if (val) {
        this.editingSowDataCopy = JSON.parse(JSON.stringify(this.editingSowData))
      }
    },
  },
  setup() {
    const {
      getValidationState,
    } = formValidation()

    return {
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
