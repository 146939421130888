<template>
  <b-card  title="合同管理">
    <b-row class="mb-1">
        <b-col>
          <div class="d-flex flex-row-reverse">
<!--             <b-button class="ml-2"  variant="primary" @click="activeUploadSidebar">上传双方已签署合同</b-button>-->            
<!--             <b-button variant="primary" @click="genContract">生成新合同</b-button>
 -->          </div>
        </b-col>
      </b-row>
    <b-card-body>
      

      <div class="d-flex justify-content-center mb-2" style="overflow:scroll">
        <span v-if="!contracts.length">暂无数据</span>
      
      <b-table class="table-responsive text-nowrap" v-else :fields="fields" :items="contracts">
        <template #cell(remain_days)="data">
          <b-badge
            v-if="computeRemainDays(data.item.contract_start_date,data.item.contract_due_date) < 0"
            variant="light-danger"
          >
            合同已过期
          </b-badge>
          <b-badge
            v-else
            variant="light-success"
          >
            {{ computeRemainDays(data.item.contract_start_date,data.item.contract_due_date) }}
          </b-badge>
        </template>

        <template #cell(contract_name)="data">
          <template v-if="!data.item.pending_contract_file_id">
          <a 
            class="text-primary"
            @click="loadFile(data.item.contract_file_id,data.item.contract_name)">
            <font-awesome-icon
              icon="fa-solid fa-paperclip"
            />
            {{ data.item.contract_name }}
          </a>
          </template>

          <template v-else>
          <a 
            class="text-primary"
            @click="loadFile(data.item.pending_contract_file_id,data.item.pending_contract_name)">
            <font-awesome-icon
              icon="fa-solid fa-paperclip"
            />
            {{ data.item.pending_contract_name }}
          </a>
          </template>


        </template>

        <template #cell(sign_status)="data">
          <b-badge v-if="data.item.sign_status === true" variant="light-success">
            双方已签署
          </b-badge>

          <b-badge v-else-if="data.item.sign_status == 'pending'" variant="light-primary">
            审批中
          </b-badge>

          <b-badge v-else-if="data.item.sign_status == 'rejected'" variant="light-danger">
            已驳回
          </b-badge>

          <b-badge v-else variant="light-secondary">
            未经双方签署
          </b-badge>


        </template>

        <template #cell(actions)="data">
          <b-dropdown v-if="data.item.sign_status !== true"
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              @click="editContract(data.item)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>


      </b-table>
    </div>

    </b-card-body>
  </b-card>
</template>


<script>
import {
  BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox,BAvatar,BButton, BCollapse,VBToggle,BCardText,BFormInput,BMedia,BMediaAside,BMediaBody,BSpinner,
  BRow,BCol,BBadge,BDropdown,BDropdownItem
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'


export default {
  components: {
    BDropdown,BDropdownItem,BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox, BAvatar,BButton,BCollapse,BCardText,BFormInput,  BRow,BCol,BMedia,BMediaAside,BMediaBody,BSpinner,BBadge
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data(){
    return{
      fileDownloading:false,
      contracts:[],
      fields:[
        {"key":"contract_iceberg_id","label":"合同编号"},
        {"key":"sign_status","label":"双方签署状态"},
        {"key":"contract_start_date","label":"生效时间"},
        {"key":"contract_due_date","label":"截止时间"},
        {"key":"remain_days","label":"剩余有效天数"},
        {"key":"contract_name","label":"合同文件"},
        {"key":"actions","label":"操作"}
      ]
    }
  },
  props:{
    contractData: {
      type: Array,
      default: () => {
        return ([])
      },}
},
  methods:{
    editContract(contractData){
      this.$emit('set-editing-contract-data',contractData)
      this.$emit('active-upload-sidebar')
    },
    deleteContract(contractId){
      this.$axios.get('/vendors/companies/del_contract/' + contractId ).then(res=>{
          if(res.data.status == "ok"){
            this.$emit('refresh-contracts')
          }
        }
      )
    },
    genContract(){
      this.$axios.get("/vendors/companies/gen_contract/" + this.$route.params.id).then(res=>{
        if(res.data.status == "ok"){
          this.$emit('refresh-contracts')
        }
      })
    },
    activeUploadSidebar(){
      this.$emit('active-upload-sidebar')
    },
    loadFile(contractFileId,fileName){
      this.$axios.get("/partner/companies/download_file/" + contractFileId).then((res)=>{
        if (res.data.status == "ok"){
          const url = this.$backend + '/download_by_token/' + res.data.data.token
          const link = document.createElement('a')
          link.setAttribute('href', url)
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
          link.remove()
        }
      })
    },
    computeRemainDays(startDate,dueDate){
      let start = new Date(startDate)
      let due = new Date(dueDate)
      let now = new Date()
      let remain = due - now
      let days = Math.floor(remain / (24 * 3600 * 1000))
      return days
    },
  },
  watch:{
    'contractData':{
      handler(val){
        this.contracts = JSON.parse(JSON.stringify(this.contractData))
      },
      deep:true
    }
  }
}
</script>

<style>

</style>
