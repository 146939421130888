<template>
  <div>
    <upload-sidebar
      :is-upload-sidebar-active.sync="isUploadSidebarActive"
      :company-data="companyData"
      @refresh-contracts="getContracts"
      :editing-contract-data="editingContractData"
      />

    <upload-sow-sidebar
      :is-upload-sow-sidebar-active.sync="isUploadSowSidebarActive"
      :company-data="companyData"
      @refresh-sows="getSows"
      :editing-sow-data="editingSowData"
      />

    <template v-if="companyData">
      <b-row class="match-height">
        <b-col cols="12" lg="12">
          <info-card :company-data="companyData"/>
        </b-col>

      </b-row>

      <b-row>
        <b-col
          cols="12"
          lg="12"
        >
        </b-col>
      </b-row>

      <contract-card
        :contract-data="contractData"
        @active-upload-sidebar="isUploadSidebarActive = true"
        @refresh-contracts="getContracts"
        @set-editing-contract-data="setEditingContractData($event)"
      />

      <sow-card
        :sow-data="sowData"
        @active-upload-sow-sidebar="isUploadSowSidebarActive = true"
        @refresh-sows="getSows"
        @set-editing-sow-data="setEditingSowData($event)"
      />

    </template>

  </div>
</template>

<script>
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
import InvoiceList from '@/views/apps/invoice/invoice-list/InvoiceList.vue'
import InfoCard from './InfoCard.vue'
import TimelineCard from './TimelineCard.vue'
import ContractCard from './ContractCard.vue'
import UploadSidebar from './UploadSidebar.vue'
import SowCard from './SowCard.vue'
import UploadSowSidebar from './UploadSowSidebar.vue'
export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    // Local Components
    InfoCard,
    TimelineCard,
    InvoiceList,
    ContractCard,
    UploadSidebar,
    SowCard,
    UploadSowSidebar,
  },
  data(){
    return{
      systemEditSidebar: false,
      contractData: [],
      sowData: [],
      companyData: {},
      isEditUserSidebarActive:false,
      isUploadSidebarActive:false,
      isUploadSowSidebarActive:false,
      timelineData: [],
      editingContractData: {},
      editingSowData: {},
    }
  },
  methods:{
    setEditingContractData(contractData){
      this.editingContractData = contractData
    },
    setEditingSowData(sowData){
      this.editingSowData = sowData
    },
    getManageCompany(){
      this.$axios.get('/partner/companies/get_manage_company').then(resp =>{
        this.companyData = resp.data.data.company
      })
    },
    getContracts(){
      this.$axios.get('/partner/companies/get_contracts').then(resp =>{
        this.contractData = resp.data.data.contracts
      })
    },
    getSows(){
      this.$axios.get('/partner/companies/get_sows').then(resp =>{
        this.sowData = resp.data.data.sows
      })
    }
  },
  created(){
    this.getManageCompany()
    this.getContracts()
    this.getSows()
  },
}
</script>
